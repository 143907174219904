<mat-sidenav-container class="sidenav-container" [autosize]="true">
  <mat-sidenav [mode]="'side'" opened [style.width.px]="isCollapsed() ? 68: 124">
    <cp-logo [isCollapsed]="isCollapsed()"></cp-logo>

    <cp-user-info *ngIf="!isCollapsed()" [user]="user()" (profileClick)="navigateToSettings()">
    </cp-user-info>

    <mat-nav-list class="sidenav-item">
      @for (nav of navItems; track nav) {
        <a
          mat-list-item
          [routerLink]="nav.link"
          [routerLinkActive]="'active'"
          [matTooltip]="isCollapsed() ? nav.label : ''"
          matTooltipPosition="right"
        >
          <mat-icon inline class="fa-lg" fontSet="fa" [fontIcon]="nav.icon"></mat-icon>
          <div [hidden]="isCollapsed()" class="mat-body-small link-label">
            {{ nav.label }}
          </div>
        </a>
      }
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content cdkVirtualScrollingElement>
    <mat-toolbar>
      <button mat-icon-button (click)="collapse()">
        <mat-icon>menu</mat-icon>
      </button>
      <!-- 2024-07-19 PB: I'm not sure what this would do I'm disabling it.
      <button mat-icon-button class="mat-body-large">
        <mat-icon inline fontSet="fa" fontIcon="fa-maximize"></mat-icon>
      </button>
      -->
      <span style="flex: 1 0 0"></span>
      <a mat-button (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span class="mat-body-small">Log out</span>
      </a>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
