import { Component, input } from '@angular/core';
import { Event } from '@cportal/api';

@Component({
  selector: 'vbn-timeline',
  standalone: true,
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss',
})
export class TimelineComponent {
  events = input<Event[]>([]);
}
