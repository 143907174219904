import { HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { inject } from '@angular/core';
import { UserStore } from './modules/shared/store/user/user.store';
import { throwError } from 'rxjs';

export const sessionInterceptor: HttpInterceptorFn = (request, next) => {
  const userStore = inject(UserStore);
  return next(request).pipe(
    catchError(err => {
      if ([HttpStatusCode.Unauthorized].includes(err.status)) {
        userStore.login();
      }
      return throwError(() => err);
    }),
  );
};
