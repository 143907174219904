import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withDebugTracing } from '@angular/router';

import { routes } from './app.routes';
import { UserService } from './user.service';
import {
  KeycloakAngularModule,
  KeycloakBearerInterceptor,
  KeycloakEventType,
  KeycloakService,
} from 'keycloak-angular';
import { environment } from '../environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Configuration, ConfigurationParameters, PortalApiModule } from '@cportal/api';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { sessionInterceptor } from './session.interceptor';
import { errorInterceptor } from './error.interceptor';

function initializeKeycloak(keycloak: KeycloakService) {
  return () => {
    keycloak.keycloakEvents$
    .subscribe((event) => {
      if (event.type === KeycloakEventType.OnTokenExpired) {
        console.log('OnTokenExpired');
        keycloak.updateToken(20);
      }
    });

    return keycloak.init({
      config: {
        url: environment.kcUrl,
        realm: environment.kcRealm,
        clientId: environment.kcClientId,
      },
      bearerPrefix: 'Bearer',
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      shouldAddToken: (request => {
        const { method, url } = request;

        if (url.includes('storage.googleapis.com')) {
          return false;
        }

        return true;
      })
    });
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(KeycloakAngularModule),
    importProvidersFrom(PortalApiModule.forRoot(apiConfigFactory)),
    UserService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([sessionInterceptor, errorInterceptor]),
    ),
    provideAnimationsAsync(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes /*, withDebugTracing()*/),
    provideEffects(),
    provideStore(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
  ],
};

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiHost,
  };
  return new Configuration(params);
}
