import { Component, inject, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatToolbar } from '@angular/material/toolbar';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { NgClass, NgIf } from '@angular/common';
import { LogoComponent } from '../logo/logo.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserInfoComponent } from '../user-info/user-info.component';
import { UserStore } from '../../modules/shared/store/user/user.store';
import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'cp-layout',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    MatListItem,
    MatMenu,
    RouterOutlet,
    MatToolbar,
    MatSidenavContent,
    MatNavList,
    MatSidenav,
    MatSidenavContainer,
    RouterLink,
    MatMenuTrigger,
    MatMenuItem,
    RouterLinkActive,
    LogoComponent,
    NgIf,
    MatButton,
    MatTooltipModule,
    MatAnchor,
    UserInfoComponent,
    NgClass,
    ScrollingModule,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  private readonly store = inject(UserStore);
  router = inject(Router);

  isCollapsed = signal(false);
  readonly user = this.store.user;

  navItems = [
    { link: '/dashboard', label: 'Dashboard', icon: 'fa-gauge' },
    { link: '/my-orders', label: 'My Orders', icon: 'fa-cubes' },
    { link: '/billing', label: 'Billing', icon: 'fa-money-bill-1' },
    { link: '/sales-materials', label: 'Sales Materials', icon: 'fa-bullseye' },
    { link: '/support', label: 'Support', icon: 'fa-comments' },
    { link: '/settings', label: 'Settings', icon: 'fa-gear' },
  ];

  async logout() {
    await this.store.logout();
  }

  collapse() {
    this.isCollapsed.update(collapsed => !collapsed);
  }

  navigateToSettings() {
    this.router.navigate(['./settings']);
  }
}
