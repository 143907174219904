import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../../../components/src/lib/alert/alert.service';
import { inject } from '@angular/core';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const alertService = inject(AlertService);

  return next(req).pipe(
    catchError((event: HttpErrorResponse) => {
      if ([HttpStatusCode.InternalServerError, HttpStatusCode.BadRequest].includes(event.status)) {
        console.log(event.error.detail);
        alertService.openSnackBar(event.error);
      }
      return next(req);
    }),
  );
};
