import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertComponent } from './alert.component';
import { ErrorJson } from '@cportal/api';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private snackBar = inject(MatSnackBar);

  openSnackBar(error: ErrorJson) {
    this.snackBar.openFromComponent(AlertComponent, {
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      duration: 5000,
      data: {
        ...error,
      },
    });
  }
}
