import { Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { JsonPipe } from '@angular/common';
import { ErrorJson } from '@cportal/api';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'vbn-alert',
  standalone: true,
  imports: [MatIcon, JsonPipe, MatIconButton],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  error = inject<ErrorJson>(MAT_SNACK_BAR_DATA);
  snackBarRef = inject(MatSnackBarRef);
}
