import { Routes } from '@angular/router';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { SalesMaterialsComponent } from './modules/sales-materials/sales-materials.component';
import { HomeComponent } from './modules/home/home.component';
import { UnknownComponent } from './modules/unknown/unknown.component';
import { AuthGuard } from './auth-guard.component';

export const routes: Routes = [
  { path: 'unknown', component: UnknownComponent, canActivate: [AuthGuard] },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/my-orders/my-orders.routes').then(m => m.routes),
  },
  {
    path: 'billing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/billing/billing.routes').then(m => m.routes),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/settings/settings.routes').then(m => m.routes),
  },
  {
    path: 'support',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/support/support.routes').then(m => m.routes),
  },
  {
    path: 'sales-materials',
    component: SalesMaterialsComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/sales-materials/sales-materials.routes').then(m => m.routes),
  },
  { path: 'test', component: HomeComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'dashboard' },
];
