import { Component, input, output } from '@angular/core';
import { MatNavList } from '@angular/material/list';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { UserJson } from '@cportal/api';

@Component({
  selector: 'cp-user-info',
  standalone: true,
  imports: [MatNavList, NgIf, MatIcon],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss',
})
export class UserInfoComponent {
  user = input<UserJson>();
  profileClick = output<void>();
}
