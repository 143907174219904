import { Component, input, output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatActionList, MatListItem } from '@angular/material/list';
import { MatCard, MatCardContent, MatCardHeader } from '@angular/material/card';
import { SalesOrderTimelineJson } from '@cportal/api';
import { StatusBadgeComponent } from '../status-badge/status-badge.component';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { TimelineComponent } from './timeline/timeline.component';

@Component({
  selector: 'vbn-orders-status-timeline',
  standalone: true,
  imports: [
    DatePipe,
    MatActionList,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatListItem,
    StatusBadgeComponent,
    MatStepper,
    MatStep,
    TimelineComponent,
  ],
  templateUrl: './orders-status-timeline.component.html',
  styleUrl: './orders-status-timeline.component.scss',
})
export class OrdersStatusTimelineComponent {
  orders = input.required<SalesOrderTimelineJson[]>();
  orderClick = output<SalesOrderTimelineJson>();
}
