@for (event of events(); track $index; let last = $last) {
  <div class="event">
    <div class="separator">
      <div class="marker">
        <div class="point"></div>
      </div>
      @if (!last) {
        <div class="connector"></div>
      }
    </div>
    <div class="content">
      <div class="mat-label-large status">{{ event.name }}</div>
      <div>{{ event.date }}</div>
      <div>{{ event.description }}</div>
    </div>
  </div>
}
