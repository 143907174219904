<div class="test">
  <h1>Simple Message Test</h1>

  <div *ngIf="user">
    <h2 *ngIf="user.customerName">Customer: {{ user.customerName }}</h2>
    <b>Send a test message</b><br />
    <input
      #messageInput
      type="text"
      [(ngModel)]="message"
      (keydown.enter)="addMessage()"
      [disabled]="messageDisabled"
    />
    &nbsp;
    <button (click)="addMessage()">Add Message</button>
  </div>

  <br />
  <hr />
  <br />

  <div *ngIf="user">
    <h2>User information</h2>
    <table>
      <tr *ngIf="user.username">
        <th scope="row">Username</th>
        <td>{{ user.username }}</td>
      </tr>
      <tr *ngIf="user.firstName">
        <th scope="row">First name</th>
        <td>{{ user.firstName }}</td>
      </tr>
      <tr *ngIf="user.lastName">
        <th scope="row">Last name</th>
        <td>{{ user.lastName }}</td>
      </tr>
      <tr *ngIf="user.email">
        <th scope="row">E-mail</th>
        <td>{{ user.email }}</td>
      </tr>
      <tr>
        <th scope="row">E-mail verified</th>
        <td>{{ user.emailVerified ? 'Yes' : 'No' }}</td>
      </tr>
    </table>
  </div>
</div>
