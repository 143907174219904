import { Component, inject } from '@angular/core';
import { LayoutComponent } from './components/layout/layout.component';
import { UserStore } from './modules/shared/store/user/user.store';

@Component({
  selector: 'cp-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [LayoutComponent],
})
export class AppComponent {
  userStore = inject(UserStore);

  constructor() {
    this.userStore.getUser();
  }
}
