<mat-card>
  <mat-card-header>
    <span class="mat-title-small --uppercase">Order Status</span>
  </mat-card-header>
  <mat-card-content>
    @if (isLoading()) {
      <p><b>Loading orders...</b></p>
    } @else {
      @if (orders() && orders().length > 0) {
        <mat-action-list>
          @for (order of orders(); let index = $index; track index) {
            <button mat-list-item (click)="orderClick.emit(order)" class="action-list-item">
              <div class="content">
                <span class="mat-body-small">
                  <span>{{ 'Ship Date' }} {{ order?.shipDate | date:'dd MMM YYYY' }}</span>
                  @if (order?.salesOrderId) {
                    {{ ' | ' }}
                    <span>ORDER#{{ order?.salesOrderId }}</span>
                  }
                </span>
                <span><vbn-status-badge [status]="order.orderMilestone"></vbn-status-badge></span>
              </div>
            </button>
            <div class="timeline">
              <vbn-timeline [events]="order.events || []"></vbn-timeline>
            </div>
          }
        </mat-action-list>
      } @else {
        <p><b>No Pending Orders.</b></p>
      }
    }
  </mat-card-content>
</mat-card>
