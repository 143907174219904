import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import {
  ContactsJson,
  DefaultService,
  PublicationJson,
  SalesOrderTimelineJson,
} from '@cportal/api';
import { inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { forkJoin, pipe, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';

interface BooksState {
  slot1: PublicationJson;
  slot2: PublicationJson;
  contacts: ContactsJson;
  ordersTimeline: SalesOrderTimelineJson[];
  isLoading: boolean;
  error?: unknown;
}

const initialState: BooksState = {
  isLoading: false,
  slot1: {},
  slot2: {},
  contacts: {},
  ordersTimeline: [],
};

export const DashboardStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods((store, defaultService = inject(DefaultService)) => ({
    loadDashboardSlots: rxMethod<void>(
      pipe(
        tap(() => patchState(store, { isLoading: true })),
        switchMap(() =>
          forkJoin({
            slot1: defaultService.publicationDashboardSlot1Get(),
            slot2: defaultService.publicationDashboardSlot2Get(),
          }).pipe(
            tapResponse({
              next: ({ slot1, slot2 }) => {
                patchState(store, { slot1: slot1, slot2: slot2, isLoading: false });
              },
              error: error => {
                patchState(store, { error, isLoading: false });
              },
            }),
          ),
        ),
      ),
    ),
    loadContacts: rxMethod<void>(
      pipe(
        tap(() => patchState(store, { isLoading: true })),
        switchMap(() =>
          defaultService.customerContactsGet().pipe(
            tapResponse({
              next: response => {
                patchState(store, { contacts: response, isLoading: false });
              },
              error: error => {
                patchState(store, { error, isLoading: false });
              },
            }),
          ),
        ),
      ),
    ),
    loadOrdersTimeline: rxMethod<void>(
      pipe(
        tap(() => patchState(store, { isLoading: true })),
        switchMap(() =>
          defaultService.salesorderTimelinesGet().pipe(
            tapResponse({
              next: response => {
                patchState(store, { ordersTimeline: response, isLoading: false });
              },
              error: error => {
                patchState(store, { error, isLoading: false });
              },
            }),
          ),
        ),
      ),
    ),
  })),
  withHooks({
    onInit(store) {
      store.loadDashboardSlots();
      store.loadContacts();
      store.loadOrdersTimeline();
    },
  }),
);
