import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../user.service';
import { User } from '../../interfaces';
import { REST_RESULT_TYPE } from '../../constants';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'cp-home',
  templateUrl: './home.component.html',
  standalone: true,
  styleUrls: ['./home.component.scss'],
  imports: [FormsModule, NgIf],
})
export class HomeComponent {
  messageDisabled = false;
  message = '';
  user: User = {} as User;

  @ViewChild('messageInput') messageInput!: ElementRef;

  constructor(private userService: UserService) {}

  addMessage() {
    this.messageDisabled = true;

    this.userService.addMessage(this.message).subscribe(result => {
      if (result && result.result === REST_RESULT_TYPE.OK) {
        console.log('Message Added');
        this.message = '';
        this.messageDisabled = false;

        setTimeout(() => {
          this.messageInput.nativeElement.focus(); // Set focus back to the input field
        });
      }
    });
  }
}
